/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        const targetElement = document.querySelector('.gn');
        const secTargetElement = document.querySelector('.lb-content');
        const disableBodyScroll = bodyScrollLock.disableBodyScroll;
        const enableBodyScroll = bodyScrollLock.enableBodyScroll;
        var $burger = $('.gn--trigger'),
            $gn = $('.gn'),
            $gnItem = $('.gn li'),
            $gnLink = $('.gn li a'),
            $navUnderlay = $('.gn-underlay'),
            $main = $('main'),
            windowWidth = $('body').width();


        /*
        |----------------------------------------------------------------
        | Navigation
        |----------------------------------------------------------------
        */
        function openNav() {
          $($burger).addClass('active');
          $($navUnderlay).addClass('is-showing');
          $($main).addClass('add-blur');
          $($gn).addClass('active').slideDown();
          bodyScrollLock.disableBodyScroll(targetElement);
        }

        function closeNav() {
          $($burger).removeClass('active');
          $($gn).removeClass('active').slideUp();
          $($navUnderlay).removeClass('is-showing');
          $($main).removeClass('add-blur');
          bodyScrollLock.enableBodyScroll(targetElement);
        }

        $($burger).on('click', function() {
          if($($gn).hasClass('active')) {
            closeNav();
          } else {
            openNav();
          }
        });

        $($gnLink).on('click', function() {
          if($('body').hasClass('home') && windowWidth < 992) {
            if($(this).parents($gnItem).hasClass('hp-anchor-link')) {
              closeNav();
            }
          }
        });

        /*
             |----------------------------------------------------------------
             |  Tabs
             |----------------------------------------------------------------
             */
            $(".tabs-holder").each(function() {
                var $self = $(this);
                var $controls = $('[data-tab]', $self);
                var $contents = $('[data-tab-id]', $self);

                $controls.click(function(e) {
                    var target = $(this).attr('data-tab');
                    var $target = $('[data-tab-id="'+target+'"]', $self);

                    if ($target.size()) {
                        e.preventDefault();

                        $controls.removeClass('active');
                        $(this).addClass('active');

                        $contents.removeClass('active');
                        $target.addClass('active');
                    }
                });

                $controls.eq(0).trigger('click');
            });


        /*
        |----------------------------------------------------------------
        |  Accordion
        |----------------------------------------------------------------
        */
        var   $shopAccordion = $('#shops-accordion'),
              $shopTrigger = $('#shops-accordion .shop-trigger'),
              $topAccordion = $('#top-accordion'),
              $bottomAccordion = $('#bottom-accordion'),
              $topAccordionTrigger = $('#top-accordion .faq-trigger'),
              $bottomAccordionTrigger = $('#bottom-accordion .faq-trigger');

            // if(windowWidth < 576) {
            //   $('#shops-accordion > li:eq(0) a').addClass('active').next().slideDown();
            // }
            $('#top-accordion > li:eq(0) a').addClass('active').next().slideDown();

            function accordion($accordionName, $trigger) {
              var $li = $('li'),
                  $content = $('.content-wrap');

              $($trigger).click(function(j) {
                  var dropDown = $(this).closest($li).find($content);

                  $(this).closest($accordionName).find($content).not(dropDown).slideUp();

                  if ($(this).hasClass('active')) {
                      $(this).removeClass('active');
                  } else {
                      $(this).closest($accordionName).find('a.active').removeClass('active');
                      $(this).addClass('active');
                  }

                  dropDown.stop(false, true).slideToggle();

                  j.preventDefault();
              });
            }

            accordion($shopAccordion, $shopTrigger);
            accordion($topAccordion, $topAccordionTrigger);
            accordion($bottomAccordion, $bottomAccordionTrigger);

        /*
        |----------------------------------------------------------------
        | Flickity
        |----------------------------------------------------------------
        */
        //Flickity Fix
        var touchingCarousel = false,
            touchStartCoords;

        document.body.addEventListener('touchstart', function(e) {
         if (e.target.closest('.flickity-slider')) {
           touchingCarousel = true;
         } else {
           touchingCarousel = false;
           return;
         }

         touchStartCoords = {
           x: e.touches[0].pageX,
           y: e.touches[0].pageY
         };
        });

        document.body.addEventListener('touchmove', function(e) {
         if (!(touchingCarousel && e.cancelable)) {
           return;
         }

         var moveVector = {
           x: e.touches[0].pageX - touchStartCoords.x,
           y: e.touches[0].pageY - touchStartCoords.y
         };

         if (Math.abs(moveVector.x) > 7) {
           e.preventDefault();
         }

        }, {passive: false});
        //Flickity Fix End

        /*!
         * Flickity asNavFor v2.0.1
         * enable asNavFor for Flickity
         */

        /*jshint browser: true, undef: true, unused: true, strict: true*/

        ( function( window, factory ) {
          // universal module definition
          /*jshint strict: false */ /*globals define, module, require */
          if ( typeof define == 'function' && define.amd ) {
            // AMD
            define( [
              'flickity/js/index',
              'fizzy-ui-utils/utils'
            ], factory );
          } else if ( typeof module == 'object' && module.exports ) {
            // CommonJS
            module.exports = factory(
              require('flickity'),
              require('fizzy-ui-utils')
            );
          } else {
            // browser global
            window.Flickity = factory(
              window.Flickity,
              window.fizzyUIUtils
            );
          }

        }( window, function factory( Flickity, utils ) {

        'use strict';

        // -------------------------- asNavFor prototype -------------------------- //

        // Flickity.defaults.asNavFor = null;

        Flickity.createMethods.push('_createAsNavFor');

        var proto = Flickity.prototype;

        proto._createAsNavFor = function() {
          this.on( 'activate', this.activateAsNavFor );
          this.on( 'deactivate', this.deactivateAsNavFor );
          this.on( 'destroy', this.destroyAsNavFor );

          var asNavForOption = this.options.asNavFor;
          if ( !asNavForOption ) {
            return;
          }
          // HACK do async, give time for other flickity to be initalized
          var _this = this;
          setTimeout( function initNavCompanion() {
            _this.setNavCompanion( asNavForOption );
          });
        };

        proto.setNavCompanion = function( elem ) {
          elem = utils.getQueryElement( elem );
          var companion = Flickity.data( elem );
          // stop if no companion or companion is self
          if ( !companion || companion == this ) {
            return;
          }

          this.navCompanion = companion;
          // companion select
          var _this = this;
          this.onNavCompanionSelect = function() {
            _this.navCompanionSelect();
          };
          companion.on( 'select', this.onNavCompanionSelect );
          // click
          this.on( 'staticClick', this.onNavStaticClick );

          this.navCompanionSelect( true );
        };

        proto.navCompanionSelect = function( isInstant ) {
          if ( !this.navCompanion ) {
            return;
          }
          // select slide that matches first cell of slide
          var selectedCell = this.navCompanion.selectedCells[0];
          var firstIndex = this.navCompanion.cells.indexOf( selectedCell );
          var lastIndex = firstIndex + this.navCompanion.selectedCells.length - 1;
          var selectIndex = Math.floor( lerp( firstIndex, lastIndex,
            this.navCompanion.cellAlign ) );
          this.selectCell( selectIndex, false, isInstant );
          // set nav selected class
          this.removeNavSelectedElements();
          // stop if companion has more cells than this one
          if ( selectIndex >= this.cells.length ) {
            return;
          }

          var selectedCells = this.cells.slice( firstIndex, lastIndex + 1 );
          this.navSelectedElements = selectedCells.map( function( cell ) {
            return cell.element;
          });
          this.changeNavSelectedClass('add');
        };

        function lerp( a, b, t ) {
          return ( b - a ) * t + a;
        }

        proto.changeNavSelectedClass = function( method ) {
          this.navSelectedElements.forEach( function( navElem ) {
            navElem.classList[ method ]('is-nav-selected');
          });
        };

        proto.activateAsNavFor = function() {
          this.navCompanionSelect( true );
        };

        proto.removeNavSelectedElements = function() {
          if ( !this.navSelectedElements ) {
            return;
          }
          this.changeNavSelectedClass('remove');
          delete this.navSelectedElements;
        };

        proto.onNavStaticClick = function( event, pointer, cellElement, cellIndex ) {
          if ( typeof cellIndex == 'number' ) {
            this.navCompanion.selectCell( cellIndex );
          }
        };

        proto.deactivateAsNavFor = function() {
          this.removeNavSelectedElements();
        };

        proto.destroyAsNavFor = function() {
          if ( !this.navCompanion ) {
            return;
          }
          this.navCompanion.off( 'select', this.onNavCompanionSelect );
          this.off( 'staticClick', this.onNavStaticClick );
          delete this.navCompanion;
        };

        // -----  ----- //

        return Flickity;

        }));

        function contentSlider($slider, $dots, $buttons, $cellAlign, $height, $responsive, $scroll, $auto) {
          $($slider).flickity({
              wrapAround: false,
              imagesLoaded: true,
              pageDots: $dots,
              freeScroll: $scroll,
              prevNextButtons: $buttons,
              cellAlign: $cellAlign,
              watchCSS: $responsive,
              adaptiveHeight: $height,
              autoPlay: $auto,
              contain: true,
              arrowShape: {
                x0: 10,
                x1: 60, y1: 50,
                x2: 60, y2: 45,
                x3: 15
              }
          });
        }

        contentSlider('.hp-gallery .slider', false, true, 'left', false, false, true, false);
        contentSlider('.pre-ftr-gallery .slider', false, true, 'left', false, false, true, false);
        contentSlider('.space-slider', true, true, 'center', false, false, true, false);
        contentSlider('.loc-callout .slider', true, true, 'center', false, false, false, 2000);

        // $('.carousel-nav').flickity({
        //   asNavFor: '.loc-callout .slider',
        //   contain: true,
        //   pageDots: false,
        //   prevNextButtons: false
        // });

        //Check if Tenant Profile Page
        if($('body').hasClass('your-tenant-profile')) {
          $tenantName = $('#tenant-name').text();
          console.log($tenantName);
          $('h2').text($tenantName);
        }

        if($('#select_user_to_edit_form').length) {
          $('#select_user_to_edit_form').find('label').text('Choose a Tenant to Edit');
        }

        if($('#select_user_to_edit_form').length) {
          $("#select_user_to_edit_form").attr('autocomplete', 'off');
        }

        if($('#wppb-register-user').length) {
          $("#wppb-register-user").attr('autocomplete', 'off');
        }

        /*
        |----------------------------------------------------------------
        | BOMA Popup
        |----------------------------------------------------------------
        */
        if($('.lb').length) {

          setTimeout(function() {
            $(".lb").addClass("is-showing");
            bodyScrollLock.disableBodyScroll(secTargetElement);
          }, 5000);

          $('.close-grad, .lb-close').on('click', function(e) {
            e.preventDefault();
            $(".lb").fadeOut(500);
            bodyScrollLock.enableBodyScroll(secTargetElement);
          });
        }


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        function work() {
            $.fn.matchHeight._update();
            $('.theme-wrap').addClass('show-theme');
        }

        if (document.readyState == 'loading') {

        } else {
          document.addEventListener('DOMContentLoaded', work);
          work();
        }

      }
    },
    'home': {
      init: function() {
        $(function() {

          var $specsBtn = $('#bldg-specs'),
              $specsContent = $('.bldg-specs-content'),
              $main = $('.main'),
              $specClose = $('#specs-close'),
              $closeLayer = $('.close-info-hp'),
              width = $('body').width();

              function closeInfoBox() {
                $($specsContent).removeClass('is-showing');
                $($main).removeClass('has-grad');
                $($closeLayer).removeClass('active');
              }

          $($closeLayer).on('click', function() {
            closeInfoBox();
          });

          $($specClose).on('click', function(e) {
            e.preventDefault();
            closeInfoBox();;
          });

          $($specsBtn).on('click', function(e) {
            e.preventDefault();
            $($specsContent).addClass('is-showing ');
            //$($closeLayer).addClass('active');
            //$($main).addClass('has-grad');
          });

          var $shopSquare = $('#shops-accordion .square'),
              $shopContent = $('#shops-accordion .content-wrap'),
              $nestedShopLink = $('.details a');

          function accordionClick() {
            $($shopSquare).on('click', function(e) {
              e.preventDefault();
              $($shopContent).hide();
              $(this).siblings('.content-wrap').delay(300).show();
            });
          }

          function storesSquareClick() {
            var $shopSquare = $('#shops-accordion .item');

            $($shopSquare).unbind().on('click', function(e) {
              e.preventDefault();
              if($(this).hasClass('active')) {
                $(this).removeClass('active');
              } else {
                $($shopSquare).removeClass('active');
                $(this).addClass('active');
              }
            });
          }

          $($nestedShopLink).click(function(e) {
            e.stopPropagation();
          });



          if(width < 576) {
            accordionClick();
          } else {
            storesSquareClick();
          }

          $(window).resize(function(event) {
            var windowWidth = $('body').width();
            if(windowWidth > 575) {
              $('.stores .accordion>li .content-wrap').slideUp();
            }

            if(windowWidth < 576) {
              accordionClick();
            } else {
              storesSquareClick();
            }
          });
        });
      }
    },
    'location': {
      init: function() {

        var $map = $("#google-map");
        var bounds = new google.maps.LatLngBounds();

        var map = new google.maps.Map(document.getElementById('google-map'), {
            styles: $map.data('style') || {},
            zoom: 17,
            scrollwheel: false,
            mapTypeControl: false,
            draggable: true,
            zoomControl: true,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        });

            var location = {
                lat: parseFloat($map.data('lat')),
                lng: parseFloat($map.data('lng')),
                url: $map.data('url'),
            };

            var marker = new google.maps.Marker({
                position: location,
                map: map,
                icon: $map.data('marker')
            });

            bounds.extend(location);

            google.maps.event.addListener(marker, 'click', function() {
                //window.open = ;
                window.open('http://maps.google.com?q='+location.url, '_blank');
            });

            google.maps.event.addDomListener(window, 'resize', function() {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });

        map.setCenter(bounds.getCenter());

      },
      finalize: function() {

      }
    },
    'page_template_template_property_mgmt': {
      init: function() {
        $('#senior-team').gridderExpander({
            scroll: true,
            scrollOffset: 120,
            scrollTo: "panel",
            animationSpeed: 750,
            animationEasing: "easeInOutExpo",
            showNav: true,
            nextText: "",
            prevText: "",
            closeText: " ",
        });
      }
    },
    'page_template_template_service_links': {
      init: function() {
        $(function() {
          $('.tsl h3').matchHeight();
        });
      }
    },
    'page_id_699': {
      //SERVICE REQUEST FORMS
      init: function() {
        $(function() {
          var $formDropBtn = $('.mobile-form-trigger'),
              $formNavList = $('.tab-controls'),
              $specificForm = $('.tab-control'),
              windowWidth = $('body').width();

          if(windowWidth < 768) {
            $($formDropBtn).on('click', function(e) {
              e.preventDefault();
              if($($formNavList).hasClass('open')) {
                $($formNavList).removeClass('open').slideUp();
              } else {
                $($formNavList).addClass('open').slideDown();
              }
            });

            $($specificForm).on('click',function() {
              $($formNavList).removeClass('open').slideUp();
            });
          }
        });
      }
    },
    'page_id_701': {
      init: function() {
        $(function() {
          if (!$('.last_profile_update_date').val()) {
            $('#wppb-form-element-60').remove();
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
